import { RiUserLine } from '@remixicon/react';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { S3_BUCKET_CDN_URL } from '~/constants';
import { cn } from '~/utils/tw';

interface AvatarProps extends ComponentProps<'div'> {
  url?: string;
  fileName?: string;
  iconSize?: number;
  iconClassName?: string;
}

export const Avatar = ({
  url,
  fileName,
  iconSize,
  iconClassName,
  className,
  ...props
}: AvatarProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  const imgUrl = useMemo(
    () => url || `${S3_BUCKET_CDN_URL}/${fileName}`,
    [url, fileName],
  );
  useEffect(() => {
    const img = new Image();
    img.src = imgUrl;
    img.onload = () => setLoaded(true);
    return () => img.remove();
  }, [imgUrl]);

  return (
    <div
      className={cn(
        'group relative flex h-8 w-8 shrink-0 items-center justify-center overflow-hidden rounded-full border',
        className,
      )}
      {...props}
    >
      {loaded ? (
        <img
          alt="pic"
          src={imgUrl}
          className="absolute h-full w-full object-cover"
        />
      ) : (
        <RiUserLine
          size={iconSize || 16}
          className={cn('text-homy-gray-light', iconClassName)}
        />
      )}
    </div>
  );
};
